let BASE_URL = 'https://wl-api-1.staging.classplus.co';

if (process.env.NODE_ENV === 'development') {
	// BASE_URL = 'https://wl-api-1.staging.classplus.co';
	BASE_URL = 'https://apip-preprod.classplusapp.com';
} else {
	if (process.env.REACT_APP_BUILD_ENV === 'staging') {
		BASE_URL = 'https://apip-preprod.classplusapp.com';
	} else if (process.env.REACT_APP_BUILD_ENV === 'production') {
		BASE_URL = 'https://api.classplusapp.com';
	}
}

export default {
	BASE_URL
};
