import React from "react";
import "./App.css";
import axios from "axios";
import AppConfig from "./AppConfig";
import Loader from "./components/Loader/Loader";
import { getQuery } from "./utils";
import toast, { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';

toastConfig({ theme: 'dark' });
class App extends React.Component {
  constructor(props) {
    super(props);
    this.queryParam = getQuery()

    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    console.log('query', getQuery())
    const {userType, profileCreated, sessionId, isZoomWebinar, zoomUrl} = this.queryParam;
    if(userType == 1 && isZoomWebinar == 1){
      let decodedUrl = atob(zoomUrl)
      window.location.href = decodedUrl
    } else if (userType == 1 && sessionId && !isZoomWebinar) {
      this.updateAttendesCountApi()
    }else if (userType == 1 && (sessionId == undefined || sessionId == null)){
      alert('Invalid Session')
    }

    if (userType == 3){
      switch (profileCreated){
        case '0':
          this.createZoomProfile();
          break;
        
        case '1':
          if(sessionId){
            this.endZoomSession();
          }else{
            if(isZoomWebinar == 1){
              this.createZoomWebinarSession();
            }else{
              this.createZoomSession();
            }
          }
      }
    }
  }

  createZoomProfile = () => {
    const {zoomUrl} = this.queryParam
    // bridge

    if (window && window.mobile && window.mobile.showFabButton) {
      window.mobile.showFabButton('1');
    }

    if (window?.webkit?.messageHandlers?.showFabButton) {
      window.webkit.messageHandlers.showFabButton.postMessage('1');
    }


    let decodedUrl = atob(zoomUrl)
    console.log('query', decodedUrl)

    // if (window && window.mobile && window.mobile.openZoomLink) {
    //   window.mobile.openZoomLink(decodedUrl);
    // }

    // if (window?.webkit?.messageHandlers?.openZoomLink) {
    //   window.webkit.messageHandlers.openZoomLink.postMessage(decodedUrl);
    // }

    // window.open(decodedUrl)
    window.location.href = decodedUrl

  }


  updateAttendesCountApi = () => {
    const {zoomUrl, token, sessionId} = this.queryParam
    const promoHeaders = {
      "Api-Version": 3,
      "x-access-token": token
    };
      axios
      .patch(
        `${AppConfig.BASE_URL}/v3/zoom/session/join/${sessionId}`,
        {sessionId},
        { headers: promoHeaders }
      )
      .then(response => {
        console.log(response)
            let decodedUrl = atob(zoomUrl)
            // if (window && window.mobile && window.mobile.openZoomLink) {
            //   window.mobile.openZoomLink(decodedUrl);
            // }
        
            // if (window?.webkit?.messageHandlers?.openZoomLink) {
            //   window.webkit.messageHandlers.openZoomLink.postMessage(decodedUrl);
            // }

        // window.open(decodedUrl)
        window.location.href = decodedUrl

      })
      .catch(error => {
        this.setState({ loading: true, error: "Internal server error" });

      });
  };

  createZoomSession = () => {
    const {zoomUrl, token, entityId, entityType} = this.queryParam;
    const promoHeaders = {
      "Api-Version": 3,
      "x-access-token": token
    };
      axios.post(
        `${AppConfig.BASE_URL}/v3/zoom/session`,
        {entityId, entityType},
        { headers: promoHeaders },
      )
      .then(response => {
        console.log(response)
        let decodedUrl = atob(zoomUrl)

        // if (window && window.mobile && window.mobile.openZoomLink) {
        //   window.mobile.openZoomLink(decodedUrl);
        // }
    
        // if (window?.webkit?.messageHandlers?.openZoomLink) {
        //   window.webkit.messageHandlers.openZoomLink.postMessage(decodedUrl);
        // }

        // window.open(decodedUrl)
        window.location.href = decodedUrl

      })
      .catch(error => {
        toast(error?.response?.data?.message)
        setTimeout(() => {
          window.history.back();
        }, 2000);
        this.setState({ loading: true, error: "Internal server error" });
      });
  };

  createZoomWebinarSession = () => {
    const {zoomUrl, token, entityIds, entityType, stackType} = this.queryParam
    const parsedEntityType = parseInt(entityType);
    const parsedStackType = parseInt(stackType);
    const parsedEntityIds = JSON.parse(decodeURIComponent(entityIds));
    const promoHeaders = {
      "Api-Version": 3,
      "x-access-token": token
    };
      axios.post(
        `${AppConfig.BASE_URL}/v3/zoom/createSession`,
        {entityIds: parsedEntityIds, entityType:parsedEntityType, zoomUrl, stackType:parsedStackType},
        { headers: promoHeaders },
      )
      .then(response => {
        console.log(response)
        let decodedUrl = atob(zoomUrl)

        // if (window && window.mobile && window.mobile.openZoomLink) {
        //   window.mobile.openZoomLink(decodedUrl);
        // }
    
        // if (window?.webkit?.messageHandlers?.openZoomLink) {
        //   window.webkit.messageHandlers.openZoomLink.postMessage(decodedUrl);
        // }

        // window.open(decodedUrl)
        window.location.href = decodedUrl

      })
      .catch(error => {
        toast(error?.response?.data?.message)
        setTimeout(() => {
          window.close();
        }, 2000);
        this.setState({ loading: true, error: error.response ? error.response.data.message : "Internal server error" });
      });
  };


  rejoinZoomSession = () => {
    const {zoomUrl} = this.queryParam

    let decodedUrl = atob(zoomUrl)
    window.location.href = decodedUrl
  };

  endZoomSession = () => {
    const {zoomUrl, token, sessionIds, rejoin, stackType} = this.queryParam
    const promoHeaders = {
      "Api-Version": 3,
      "x-access-token": token
    };

    if (rejoin && rejoin == '1'){
      this.rejoinZoomSession()
      return;
    }

      axios
      .patch(
        `${AppConfig.BASE_URL}/v3/zoom/endSession/${sessionIds}`,
        {sessionIds, stackType},
        { headers: promoHeaders }
      )
      .then(response => {

        console.log(response)
        //bridge back button
        if (window && window.mobile && window.mobile.clickBackPressed) {
          window.mobile.clickBackPressed();
        }
    
        if (window?.webkit?.messageHandlers?.clickBackPressed) {
          window.webkit.messageHandlers.clickBackPressed.postMessage('1');
        }
      })
      .catch(error => {
        this.setState({ loading: true, error: "Internal server error" });
      });
  };

  render() {
    let {  loading} = this.state;
    return (<div className="App">
        {loading && (
          <div className="ErrorContainer">
            <span>Redirecting to Zoom...</span>
          </div>
        )}
        {loading && <Loader />}
      </div>)
  }
}

export default App;
