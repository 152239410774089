import React from 'react';
import Spinner from "../UI/Spinner/Spinner";
export default function Loader() {
	return (
		<div
			style={{
				position: 'fixed',
				width: '100%',
				height: '100vh',
				zIndex: 1,
				opacity: 0.5,
                background: '#FFFFFF',
                top: 0
			}}
		>
			<Spinner />
		</div>
	);
}
